import { render, staticRenderFns } from "./subdivisionsMain.vue?vue&type=template&id=77bc5afa&scoped=true&"
import script from "./subdivisionsMain.vue?vue&type=script&lang=js&"
export * from "./subdivisionsMain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77bc5afa",
  null
  
)

export default component.exports