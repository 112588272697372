<template>
  <div>
    <h2>Структурные подразделения</h2>

    <subdivisions-table></subdivisions-table>
  </div>
</template>

<script>
import SubdivisionsTable from "@/components/ecologist/subdivisions/subdivisionsTable";
export default {
  name: "subdivisionsMain",
  components: { SubdivisionsTable },
};
</script>

<style scoped></style>
