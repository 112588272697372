<template>
  <div>
    <search-block :title="подразделения">
      <el-row :gutter="10">
        <el-col :span="20">
          <el-input @input="searchUnit" v-model="search"></el-input>
        </el-col>
        <el-col :span="4">
          <el-button @click="showAddModal" type="primary"> Добавить</el-button>
        </el-col>
      </el-row>
    </search-block>

    <el-table stripe :data="unitList.content">
      <el-table-column label="Наименование">
        <template slot-scope="row">
          {{ row.row.title }} ({{ row.row.path }})
        </template>
      </el-table-column>
      <el-table-column
        label="Номер телефона"
        prop="phoneNumber"
      ></el-table-column>
      <el-table-column label="Почта" prop="email"></el-table-column>
      <el-table-column label="Адрес">
        <template slot-scope="row">
          <p v-if="row.row.areaInfo.name !== null">
            Обл. {{ row.row.areaInfo.name }}
          </p>
          <p v-if="row.row.districtInfo.name !== null">
            Р-н {{ row.row.districtInfo.name }}.
          </p>
          <p>{{ row.row.cityInfo.name }}</p>
          <p v-if="row.row.street !== null">Ул. {{ row.row.street }}</p>
        </template>
      </el-table-column>
      <el-table-column label="Ответственные">
        <template slot-scope="row">
          <ul>
            <li
              :key="key"
              v-for="(responsible, key) in row.row.responsiblePersonsInfo"
            >
              {{ responsible.name }}
            </li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column label="">
        <template slot-scope="row">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-button
                size="mini"
                style="width: 100%"
                @click="showUpdateModal(row)"
                type="warning"
                >Редактировать</el-button
              >
              <el-button
                @click="deleteSubdiv(row)"
                size="mini"
                style="width: 100%; margin-left: 0; margin-top: 5px"
                type="danger"
                >Удалить</el-button
              >
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center"
      layout="total, sizes, prev, pager, next"
      background
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="unitList.size"
      :current-page.sync="unitList.number + 1"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="unitList.totalElements"
    >
    </el-pagination>

    <custom-modal
      title="Добавить подразделение"
      :visible="showModal === 'addSubDivision'"
    >
      <subdivisions-add></subdivisions-add>
    </custom-modal>

    <custom-modal
      title="Изменить подразделение"
      :visible="showModal === 'updateSubDivision'"
    >
      <subdivisions-edit
        :selected-sub-division="selectedSubDiv"
      ></subdivisions-edit>
    </custom-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomModal from "@/components/customModal";
import SubdivisionsAdd from "@/components/ecologist/subdivisions/subdivisionsAdd";
import SubdivisionsEdit from "@/components/ecologist/subdivisions/subdivisionsEdit";
import SearchBlock from "@/components/searchBlock";

export default {
  name: "subdivisionsTable",
  components: { SearchBlock, SubdivisionsEdit, SubdivisionsAdd, CustomModal },
  data() {
    return {
      search: "",
      UNIT_TYPE: 2,
      selectedSubDiv: {},
    };
  },

  computed: {
    ...mapGetters({
      unitList: "GETUNITSLIST",
      showModal: "GETSHOWMODAL",
    }),
  },

  methods: {
    searchUnit(query) {
      this.$store.dispatch("searchUnits", {
        unitType: this.UNIT_TYPE,
        page: 0,
        size: 20,
        query: query,
      });
    },

    showUpdateModal(item) {
      console.log(item.row);
      this.selectedSubDiv = item.row;
      if (this.selectedSubDiv.dateFrom!=null)
      this.selectedSubDiv.dateFrom=new Date(this.selectedSubDiv.dateFrom);
      if (this.selectedSubDiv.dateTo!=null)
      this.selectedSubDiv.dateTo=new Date(this.selectedSubDiv.dateTo);
      this.$store.dispatch("getDistrictNames", {
        areaId: this.selectedSubDiv.areaInfo.id,
        query: this.selectedSubDiv.districtInfo.name,
      });
      this.$store.dispatch("getCitiesNamesForUser", {
        areaId: this.selectedSubDiv.areaInfo.id,
        districtId: this.selectedSubDiv.districtInfo.id,
        query: this.selectedSubDiv.cityInfo.name,
      });
      this.$store.dispatch("setShowModal", "updateSubDivision");
    },

    handleSizeChange(val) {
      this.getListUnits({ page: 0, size: val });
    },

    handleCurrentChange(val) {
      this.getListUnits({ page: val - 1, size: this.unitList.size });
    },

    showAddModal() {
      this.$store.dispatch("setShowModal", "addSubDivision");
    },

    edit(item) {
      this.$router.push("edit/" + item.row.organizationalUnitId);
    },

    deleteSubdiv(item) {
      this.confirm(
        "Удалить подразделение",
        "Вы действительно хотите удалить подразделение",
        "warning",
        "Удалить",
        "Отмена"
      ).then(() => {
        this.$store
          .dispatch("deleteOrganizationUnit", item.row.organizationId)
          .then(() => {
            this.notification("Успешно", "Подразделение удалено", "success");
            this.getListUnits({
              page: this.unitList.number,
              size: this.unitList.size,
            });
          });
      });
    },

    getListUnits(data) {
      this.$store.dispatch("getListUnits", {
        unitType: this.UNIT_TYPE,
        page: data.page,
        size: data.size,
      });
    },
  },

  mounted() {
    this.getListUnits({ size: 20, page: 0 });
  },
};
</script>

<style scoped></style>
