<template>
  <div>
    <subdivisions-main></subdivisions-main>
  </div>
</template>

<script>
import SubdivisionsMain from "@/components/ecologist/subdivisions/subdivisionsMain";
export default {
  name: "subdivisionsMainView",
  components: { SubdivisionsMain },
};
</script>

<style scoped></style>
